.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.testimonial {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.testimonial--content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
}

.testimonial--content {
  width: 60%;
}

.testimonial--content-text {
    font-size: 1.4rem;
    margin: 0 10%;
  }

.testimonial--img {
    width: 40%;
  img {
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    align-items: center;
  }
  .testimonial {
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
  .testimonial--content {
    width: 100%;
    margin-bottom: 5%;
  }

  .testimonial--content-text {
    font-size: 1.1rem;
    margin-left:0;
    margin-right:0;
    margin-bottom: 5%;
  }

  .testimonial--img {
    width: 100%;
  }
}
