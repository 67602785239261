@import '../../styles/partials/_variables.scss';

.container {
  color: $red-pastel;
}

.row {
  display: flex;
  align-items: center;
}

.hero--pic {
  display: flex;
  justify-items: flex-end;
}

.hero--pic-img {
  border-radius: 50%;
  width: 100px;
}

.hero--infos {
  margin: 0 5%;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }

  .row {
    justify-content: center;
  }

  .hero--pic-img {
    margin: 0 5%;
    width: 70px;
  }
}
