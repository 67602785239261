@import '../../styles/partials/_variables.scss';

.container {
  background-image: url('../../assets/img/welcome.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  // animation: huerotate 0.5s linear infinite alternate ;
}

.title--container {
  position: absolute;
  top: 32%;
  left: 6%;
  text-align: justify;
  display: flex;
  flex-direction:column;
}

.title--text {
  font-size: $font-size-main-title;
  font-weight: $font-weight-black;
  color: $pink-pastel;
}

.title--text-bottom{
  display: flex;
  flex: row nowrap;
}

.title--text-accent {
  color: $red-pastel;
}

.cubespinner {
  animation: spincube 8s infinite ease-in-out;
  transform-style: preserve-3d;
  transform-origin: 40px 40px 0;
  font-size: $font-size-main-title;
  font-weight: $font-weight-black;
  
  div {
    position: absolute;
    height: 80px;
    text-align: left;
  }

  .face1 {
    //color: #f1c40f;
    color: #f85555;
    transform: translateZ(40px);
    backface-visibility: hidden;
  }
  .face2 {
    color: #fff;
    transform: rotateX(90deg) translateZ(40px);
    backface-visibility: hidden;
  }
  .face3 {
    color: #f85555;
    transform: rotateX(180deg) translateZ(40px);
    backface-visibility: hidden;
  }
  .face4 {
    //color: #6a26e9;
    color: #fff;
    transform: rotateX(270deg) translateZ(40px);
    backface-visibility: hidden;
  }
}

.subtitle--container{
  position: absolute;
  top: 70%;
  left: 37%;
  font-size: $font-size-subtitle;
  font-weight: $font-weight-medium;
  color: $gray-medium;
}
/*Cubical Flipping or rotation*/
@keyframes spincube {
  from,
  to {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  15% {
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    transform: rotateX(90deg);
  }
  25% {
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    transform: rotateX(90deg);
  }
  40% {
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
  50% {
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
  65% {
    -webkit-transform: rotateX(270deg);
    -moz-transform: rotateX(270deg);
    -ms-transform: rotateX(270deg);
    transform: rotateX(270deg);
  }
  75% {
    -webkit-transform: rotateX(270deg);
    -moz-transform: rotateX(270deg);
    -ms-transform: rotateX(270deg);
    transform: rotateX(270deg);
  }
  90% {
    -webkit-transform: rotateX(360deg);
    -moz-transform: rotateX(360deg);
    -ms-transform: rotateX(360deg);
    transform: rotateX(360deg);
  }
  100% {
    -webkit-transform: rotateX(360deg);
    -moz-transform: rotateX(360deg);
    -ms-transform: rotateX(360deg);
    transform: rotateX(360deg);
  }
}

@keyframes huerotate {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(120deg);
  }
}
