@import '../../styles/partials/variables';

.card {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  min-height: 100%;
  justify-content: center;
}

.card--content {
  background-color: $white;
  min-height: 100%;
  overflow: hidden;
}

.card--content:hover .card--pic {
  opacity: 0.4;
  transition: 1s ease-in-out;
  transform: translateX(30%);
  z-index: 0;
}

.card--content .card--pic {
  transition: 0.5s ease-in-out;
  transform: translateX(0%);
}

.card--pic-img {
  width: 100%;
}

.card--details {
  position: absolute;
  transition: transform 0.6s ease-in-out;
  transform-origin: left;
  transform: perspective(2000px) rotateY(-90deg);
  background-color: $gray-dark;
  z-index: 1;

  p {
    text-align: justify;
    margin: 10% 5%;
    color: $pewter-blue;
  }
}

.card--active {
  transform: perspective(2000px) rotateY(0deg);
}