@import '../../styles/partials/variables';

.navbar-sticky {
  width: 100%;
  background: $gray-dark;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  animation: moveDown 0.8s ease-in-out;
  z-index: 10;

  a {
    color: white;
  }
}

.navbar--logo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar--logo {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  animation: rotate 0.7s ease-in-out 0.5s;
}

.navbar--links {
  display: flex;
  list-style: none;
  justify-content: space-around;
  width: 60%;
}

.navbar--links-accent {
  a {
    color: #C13C13;
  }
}

.burger {
  display: none;
  cursor: pointer;

  div {
    width: 25px;
    height: 3px;
    background-color: #222;
    margin: 5px;
    transition: all 0.3s ease;
  }
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0rem);
  }
}

@media screen and (max-width: 1024px) {
  .navbar--links {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .navbar--links {
    position: fixed;
    right: 0;
    height: 94vh;
    top: 6vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e6e6e6;
    z-index: 1;
    width: 50%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;

    li {
      opacity: 0;
    }
  }
  .burger {
    display: block;
  }
}

.nav-active {
  transform: translateX(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.toggle {
  .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  .line2 {
    opacity: 0;
  }
  .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
