@import '../../styles/partials/variables';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3%;
}

.form--container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.form--container-fields {
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    justify-content: space-between;
    margin-top: 2%;
  }

  width: 50%;
  background-color: #9c9c9c52;
}

.form--container-video {
  width: 30%;
  background-color: #363232;
  video {
    width: 100%;
    height: 100%;
  }
}

.input--button {
  background-color: #bf8100;
  color: $gray-dark;
  font-family: $font-family-sans-serif;
  width: 15%;
  height: 70%;
  font-size: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.12rem;
}

.input--button:disabled {
  background-color: #352605;
}

.fields--group {
  height: 10%;
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  label {
    width: 15%;
  }

  input {
    width: 60%;
  }

  p {
    width: 25%;
  }

  textarea {
    width: 60%;
  }
}

.fields--group-input{
  height: 10%;
}

.fields--group-textarea{
  height: 45%;
}

.error {
  color: #923c26;
}

.modal--content {
  font-family: $font-family-sans-serif !important;
  font-size: 0.8rem !important;
}

.modal--title {
  font-family: $font-family-sans-serif !important;
  font-size: 1rem !important;
}

.modal--button {
  font-size: 0.6rem !important;
  background-color: #bf8100 !important;
  color: $gray-dark !important;
  font-family: $font-family-sans-serif !important;
}

@media screen and (max-width: 1168px) {

  .form--container {
    height: 70vh;
    flex-direction: column-reverse;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
  }

  .form--container-fields {
    width: 80%;
    height: 60%;
  }

  .fields--group{
    flex-direction: column;

    label {
      width: 90%;
    }
  
    input {
      width: 90%;
    }
  
    p {
      width: 90%;
    }

    textarea{
      width:90%;
    }
  }
  .fields--group-textarea{
    height: 45%;
    textarea{
      height:80%;
    }
  }

  .form--container-video {
    width: 80%;
    background-color: #363232;
    video {
      width: 70%;
    }
  }



  .input--button {
    width:30%;
  }
}