// Colors
$white: #fff !default;
$pink-pastel: #d69b9b !default;
$red-pastel: #563939 ! default;
$gray-dark: #243a45 !default;
$gray-medium: #21252952 !default;
$gray-light:#9c9c9c !default;
$platinium: #e5e5e5 !default;
$pewter-blue: #9DB4C0 !default;

// Typography
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-main-title: 2.2rem !default;
$font-size-subtitle: 1.5rem !default;
$font-size-separator-title: 0.4rem !default;
$font-size-h1: 1.5rem !default;

$line-height-base: 1.5 !default;

$font-weight-thin: 100 !default;
$font-weight-extra-light: 200 !default;
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-extra-bold: 800 !default;
$font-weight-black: 900 !default;

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Roboto&display=swap');

// Font Variables
$fira-sans-font: 'Fira Sans', sans-serif;
$roboto-font: 'Roboto', sans-serif;

$font-family-sans-serif: $fira-sans-font;

$font-family-base: $font-family-sans-serif !default;

$font-weight-base: $font-weight-regular !default;

// Main Styles
$body-bg: $platinium !default;
$text-color: $gray-dark !default;