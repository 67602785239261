@import '../../styles/partials/_variables.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.customers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3%;
}
.img--container {
  margin: 2% 3%;
  width: 6%;
  max-width: 130px;
  min-width: 80px;
  img {
    width: 95%;
  }
}
.text--container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.text--content {
  color: $gray-dark;
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .text--container {
    justify-content: center;
  }
}
