@import 'partials/variables';
@import 'partials/mixins';
@import 'partials/base';

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 6vh;
  background-color: #e6e6e6;
  color: #222;

  a {
    color: #222;
  }
}

h1 {
  font-size: $font-size-h1;
  padding:0 5%;
}

h2 {
  font-size: 1rem;
}

h3 {
  font-size: 0.7rem;
}

p,
a,
input {
  font-size: 0.5rem;
  margin: 0em;
}

input,
textarea{
  font-size: 0.4rem;
  font-family: $font-family-sans-serif;
  border: 0;
}

textarea{
resize: none;
}

label{
  display: flex;
  font-size: 0.45rem;
  text-transform: uppercase;
  letter-spacing: 0.12rem;
}

a {
  text-decoration: none;
  letter-spacing: 0.25rem;
}

footer {
  background-color: #253237;
  a,
  p {
    color: $pewter-blue;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 768px) {
  p,
  a {
    font-size: 0.65rem;
    margin: 0em;
  }
}
