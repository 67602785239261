.container {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 0 2%;
}

.title {
  margin-bottom: 5%;
  display: flex;
}

@media screen and (max-width: 768px) {
  .container {
    width: 80%;
  }
}
