@import '../../styles/partials/_variables.scss';

.container{
    height: 12vh;
    display: flex;
    width: 100%;
    align-items: center;
}

.title--left{   
    justify-self: start;
}
.title--right{
    margin-left: auto;
    justify-self: end; 
}
.title--text{
    margin: 0 2rem;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-size: $font-size-separator-title;
}
