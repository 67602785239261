@import '../../styles/partials/variables';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 3%;
}

.projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 5%;

  a {
    color: $gray-dark;
    font-weight: bold;
  }
}

.card--project {
  width: 28%;
  margin-left: 2%;
  margin-right: 2%;
  min-width: 340px;
}

@media screen and (max-width: 768px) {
  .projects {
    flex-direction: column;
  }
  .card--project {
    width: 100%;
    margin-top: 5%;
  }
}
