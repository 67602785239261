*{
  margin: 0; // 1
  padding: 0;
  box-sizing: content-box;
}

html{
  font-size: calc(1rem + 1vw);
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $text-color;
  text-align: center; 
  background-color: $body-bg;
}

.resizeable-both {
  @include resizable(both);
  display: block;
}
