@import '../../styles/partials/variables';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2% 0%;
}

.tools--container{
  display: flex;
  width: 100%;
  justify-content: center;
}

.tools--list{
  width:40%;
}

@media screen and (max-width: 768px) {
  .tools--container {
    flex-direction: column;
    align-items: center;
  }
  .tools--list{
    width:80%;
  }
}