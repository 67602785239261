.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 3% 0px;
  font-size: 0.5rem;
}

.skill--logo {
  width: 15%;
  margin-right: 4%;

  p {
    font-size: 0.4rem;
  }
}

.skill--ratebar {
  width: 80%;
  background-color: #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.skill--rate {
  width: 10%;
  transition: width 1s;
  transition-timing-function: cubic-bezier(.55,.42,.45,1.36);
}



.logo {
  width: 50%;
}
