@import '../../styles/partials/variables';

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer--links {
  display: flex;
  list-style: none;
  justify-content: space-around;
  width: 100%;
  margin-top: 1%;
}

.footer--line {
  width: 50%;
  height: 1px;
  background-color: #e5e5e5;
  margin: 1.3% 0;
}

.footer--pictos {
  margin-top: 1%;
  width: 10%;
  fill: $pewter-blue;
}

.footer--copyright{
    margin-bottom: 1%;
}
