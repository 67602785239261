.container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.card--item{
    width: 25%;
    margin: 2% 2%;
}


@media screen and (max-width: 1024px) {
    .card--item{
        width: 45%;
    }
}
  
  @media screen and (max-width: 768px) {
    .card--item{
        width: 95%;
    }
  }