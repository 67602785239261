.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 8%;
}

.tools {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 5%;
}

.img--container {
  img {
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .img--container {
    img {
      width: 65%;
    }
  }
}
