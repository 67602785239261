@import '../../styles/partials/variables';

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 4%;
  width: 100%;
}

.header--title {
  width: 60%;
}

.header--tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: $gray-light;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
  p {
    width: 40%;
    text-align: start;
  }
  p::first-letter {
    text-transform: uppercase;
  }
}

@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
  }

  .header--title {
    width: 100%;
    margin-bottom: 5%;
  }

  .header--tags {
    width: 100%;
    margin-bottom: 5%;
  }
}
