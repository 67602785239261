@mixin resizable($direction) {
  overflow: auto; // Per CSS3 UI, `resize` only applies when `overflow` isn't `visible`
  resize: $direction; // Options: horizontal, vertical, both
}

@mixin fontface(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf svg
) {
  $src: null;
  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_'),
  );
  $formats: (
    otf: 'opentype',
    ttf: 'truetype',
  );
  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(
      map-has-key($formats, $ext),
      map-get($formats, $ext),
      $ext
    );
    $src: append(
      $src,
      url(quote($path + '.' + $extmod)) format(quote($format)),
      comma
    );
  }
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

/// Mixin to prefix a property
@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }
  // Output standard non-prefixed declaration
  #{$property}: $value;
}
