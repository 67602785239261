@import '../../styles/partials/_variables.scss';

.container {
  height: 80vh;
  display: flex;
  flex-direction: column;
}

.presentation {
  height: 90%;
  display: flex;
  justify-content: center;
}

.presentation--title {
  margin: 1% 2%;
  background: #eeaeca;
  background: radial-gradient(circle, #634653 0%, #7aa2d2 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
}

.presentation--img {
  width: 50%;
  height: 100%;
  background: url('../../assets/img/presentation.jpg') no-repeat left;
  background-size: cover;
}

.presentation--content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #9c9c9c52;
}

.presentation--content-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 60%;
  p {
    font-size: 0.75rem;
    margin:0 5%;
    line-height: 1.5rem;
  }
}

.hero {
  display: flex;
  justify-content: center;
}


@media screen and (max-width: 768px) {
  .presentation {
    flex-direction: column;
    width: 100%;
  }

  .presentation--img{
    width:100%;
    height: 45%;
  }
  .presentation--content{
    width:100%;
    height: 55%;
  }
}